import React, { useEffect } from "react"

import useFeatureFlagContext from "decentraland-gatsby/dist/context/FeatureFlag/useFeatureFlagContext"
import { Loader } from "decentraland-ui/dist/components/Loader/Loader"

import Banner from "../../../components/Contentful/Banner/Banner"
import CardsSection from "../../../components/Contentful/CardsSection/CardsSection"
import CarouselSection from "../../../components/Contentful/CarouselSection/CarouselSection"
import ListSection from "../../../components/Contentful/ListSection/ListSection"
import MainBanner from "../../../components/Contentful/MainBanner/MainBanner"
import EventNavbar from "../../../components/EventNavbar/EventNavbar"
import { LandingFooter } from "../../../components/Landing/LandingFooter/LandingFooter"
import Layout from "../../../components/Layout/Layout"
import NotFound from "../../../components/NotFound/NotFound"
import useEventQuery, { Event } from "../../../hooks/useEventQuery"
import useLandingContentfulQuery from "../../../hooks/useLandingContentfulQuery"
import { FeatureFlag } from "../../../modules/ff"

import "./index.css"

function getSection(section: Event["sections"][0], index: number) {
  if (section.content_type === "ui-carousel") {
    return <CarouselSection key={`event-page-carousel-${index}`} {...section} />
  } else if (section.content_type === "ui-cards-section") {
    return <CardsSection key={`event-page-carousel-${index}`} {...section} />
  } else if (section.content_type === "ui-list-section") {
    return <ListSection key={`event-list-section-${index}`} {...section} />
  } else if (section.content_type === "ui-banner") {
    return <Banner key={`event-banner-${index}`} {...section} />
  }

  return null
}

export default function EventPage(props: { event: string }) {
  const [ff, { loaded: featureFlagsLoaded, loading: featureFlagsLoading }] =
    useFeatureFlagContext()
  const event = useEventQuery(props.event)
  const landing = useLandingContentfulQuery()

  useEffect(() => {
    if (event) {
      document.documentElement.style.setProperty(
        "--event-background-color",
        event.background_color || ""
      )
    }
  }, [event])

  if (!featureFlagsLoaded || featureFlagsLoading) {
    return <Loader active size="large" />
  }

  if (!ff.flags[FeatureFlag.ConferencePage] || !event) {
    return <NotFound />
  }

  return (
    <Layout className="landing-event" hideNavbar>
      <EventNavbar
        logo={event.logo.url}
        title={event.title}
        id={event.event_id}
      />
      <MainBanner {...event.main_banner} />
      {event.sections.map(getSection)}
      <LandingFooter footer={landing.footer} />
    </Layout>
  )
}
